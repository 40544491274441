<template>
    <section class="pt-4 px-4">
        <div class="row mx-0 bg-white br-t-8 py-3">
            <div class="col-12 px-3 mx-4">
                <p class="text-general f-17 f-600">
                    Compras realizadas donde se aplicó un cupón
                </p>
            </div>
        </div>
        <div class="row mx-0 position-relative bg-white br-b-8 pb-3">
            <cargando v-if="cargandoComparativos" />
            <div class="col-auto">
                <div v-for="(title, t) in filters_name" :key="t" class="row mx-0 my-3 cr-pointer" @click="active_filter = t">
                    <div class="col-12 text-center" :class="active_filter == t ? 'active-filter' : 'inactive-filter'">
                        {{ title.title }}
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row mx-0">
                    <div v-for="(filter, f) in relaciones" :key="f" class="card-filter mt-2 mx-2">
                        <div class="bg-fondo text-general py-1 px-2 br-12 title-filter d-middle">
                            {{ filter.title }}
                        </div>
                        <div class="row mx-0">
                            <div class="col-12 my-2">
                                <i class="f-18" :class="`${ filter.porcentaje < 0 ? 'icon-down text-general-red' : filter.porcentaje > 0 ? 'icon-up text-general' : '' }`" />
                                <span :class="`${ filter.porcentaje < 0 ? 'text-general-red' : filter.porcentaje > 0 ? 'text-general' : '' }`">
                                    {{ filter.porcentaje }}%
                                </span>
                            </div>
                            <div class="col-12 f-22 text-general2">
                                {{ f != 0 && f != 5 ? separadorNumero(filter.valor) : f == 5 ? `${agregarSeparadoresNumero(filter.valor,1)} %` : `${agregarSeparadoresNumero(filter.valor)}` }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 px-4 mt-4 bg-white br-8 py-3">
            <div class="col-12">
                <p class="text-general f-600 f-17">
                    Cupones disponibles por cedis
                </p>
            </div>
            <div class="col-12 position-relative">
                <cargando v-if="cargandoCuponesCedis" />
                <echart :options="cuponesDisponiblesCedis" />
                <div v-if="cuponesDisponiblesCedis.dataset.source.length == 0" class="position-absolute h-100 w-100" style="top:0px;left:0px;opacity:0.8;filter:blur(6px);background-color:#ffffff;z-index:1;" />
                <div v-if="cuponesDisponiblesCedis.dataset.source.length == 0" class="img-sindatos text-center">
                    <img class="obj-cover" src="/img/otros/no_datos.svg" />
                </div>
            </div>
        </div>
        <div class="row mx-0 px-3 mt-4 position-relative bg-white br-8 py-3">
            <cargando v-if="cargandoCuponesFechas" />
            <div class="col-12">
                <div class="bg-white br-8 p-2">
                    <div class="row mt-2">
                        <div class="col-8">
                            <p class="text-general f-600 f-17 mb-3 pl-3"> Aplicación de cupones en las últimas 16 semanas </p>
                            <echart :options="cuponesMayorCantidadSemana" />
                            <div v-if="_.sumBy(cuponesMayorCantidadSemana.dataset.source,'cantidad') == 0 && _.sumBy(cuponesMayorCantidadSemana.dataset.source,'valor_final_2') == 0" class="position-absolute h-100 w-100" style="top:0px;left:0px;opacity:0.8;filter:blur(6px);background-color:#ffffff;z-index:1;" />
                            <div v-if="_.sumBy(cuponesMayorCantidadSemana.dataset.source,'cantidad') == 0 && _.sumBy(cuponesMayorCantidadSemana.dataset.source,'valor_final_2') == 0" class="img-sindatos text-center">
                                <img class="obj-cover" src="/img/otros/no_datos.svg" />
                            </div>
                        </div>
                        <div class="col-4">
                            <p class="text-general f-600 f-17 mb-3 pl-3"> Aplicacion en los ultimos 3 meses </p>
                            <echart :options="cuponesMayorCantidadMes" />
                            <div v-if="_.sumBy(cuponesMayorCantidadMes.dataset.source,'cantidad') == 0 && _.sumBy(cuponesMayorCantidadMes.dataset.source,'valor_final_2') == 0" class="position-absolute h-100 w-100" style="top:0px;left:0px;opacity:0.8;filter:blur(6px);background-color:#ffffff;z-index:1;" />
                            <div v-if="_.sumBy(cuponesMayorCantidadMes.dataset.source,'cantidad') == 0 && _.sumBy(cuponesMayorCantidadMes.dataset.source,'valor_final_2') == 0" class="img-sindatos text-center">
                                <img class="obj-cover" src="/img/otros/no_datos.svg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 align-items-center mb-4 mt-5">
            <div class="col-auto d-middle text-general f-15 f-500">
                Rango de análisis
            </div>
            <div class="col-auto" style="width:174px;">
                <el-select v-model="rango" placeholder="Seleccionar" size="small">
                    <el-option
                    v-for="item in rangos"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-auto px-0 mx-2" style="width:248px;">
                <div class="date-picker-edit">
                    <el-date-picker
                    v-model="fecha"
                    class="w-100"
                    size="small"
                    format="dd-MM-yyyy"
                    value-format="yyyy-MM-dd"
                    :disabled="disable_fecha"
                    popper-class="date-picker-edit-popper"
                    type="daterange"
                    unlink-panels
                    start-placeholder="Fecha inicio"
                    end-placeholder="Fecha fin"
                    />
                </div>
            </div>
            <div class="col-5 my-1 d-middle justify-content-end">
                <div class="col-auto">
                    <span class="text-general f-500 f-14 mx-2">
                        Criterios
                    </span>
                    <el-select v-model="criterio" size="small">
                        <el-option
                        v-for="item in options"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-auto px-0">
                    <div class="bg-general text-white px-3 py-1 br-10" @click="informe">
                        Mostrar Informe
                    </div>
                </div>
            </div>
            <cargando v-if="cargandoInforme" mini />
        </div>
        <div class="row mx-0 px-4 my-3 bg-white br-8 py-3">
            <div class="col-12">
                <p class="text-general f-18 f-600">
                    Los "20" Cupones con mayor cantidad de usos
                </p>
            </div>
            <div class="col-12 mt-3">
                <echart v-if="criterio == 1" :options="cuponesMayorCantidad" />
                <echart v-else-if="criterio == 2" :options="cuponesGeneros" />
                <echart v-else-if="criterio == 3" :options="cuponesEdades" />
                <echart v-else-if="criterio == 4" :options="cuponesAntiguedad" />
                <echart v-else-if="criterio == 5" :options="cuponesCantidadPedidos" />
                <div v-if="validarGrafica" class="position-absolute h-100 w-100" style="top:0px;left:0px;opacity:0.8;filter:blur(6px);background-color:#ffffff;z-index:1;" />
                <div v-if="validarGrafica" class="img-sindatos text-center">
                    <img class="obj-cover" src="/img/otros/no_datos.svg" />
                </div>
            </div>
        </div>
        <div class="row mx-0 align-items-center mt-5 mb-4">
            <div class="col-auto">
                <span class="text-general f-14 mx-2 f-500">Criterios</span>
                <el-select v-model="filtro" size="small">
                    <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <template v-if="filtro > 2 ">
                <div class="col-2 d-middle">
                    <span class="text-general mx-1 f-14 f-500">
                        De
                    </span>
                    <el-input v-model="inicio" size="small" class="w-100" />
                </div>
                <div class="col-2 d-middle">
                    <span class="text-general mx-1 f-14 f-500">
                        A
                    </span>
                    <el-input v-model="fin" size="small" class="w-100" />
                </div>
            </template>
            <template v-else-if="filtro == 2">
                <div class="col-auto d-middle">
                    <el-checkbox-group v-model="criterioGeneros">
                        <el-checkbox :label="2" class="check-red">
                            Hombre
                        </el-checkbox>
                        <el-checkbox :label="3" class="check-red">
                            Mujer
                        </el-checkbox>
                        <el-checkbox :label="1" class="check-red">
                            Otro
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </template>
            <div class="col-auto d-middle">
                <div class="bg-general text-white cr-pointer br-10 px-3 py-1" @click="cuponesIngresosInforme">
                    Generar
                </div>
            </div>
            <cargando v-if="cargandoCriterios" mini />
        </div>
        <div class="row mx-0 px-4 my-3 bg-white br-8 py-3">
            <div class="col-12 text-general f-600 f-17">
                Cupones con mayores ingresos
            </div>
            <div class="col-12">
                <div class="bg-white br-8 p-2">
                    <div class="row mt-3">
                        <div class="col-12 text-general pl-3 f-600 f-17">
                            Cupones con mayores ingresos
                        </div>
                        <div class="col-12">
                            <echart :options="utilidad" />
                            <div v-if="utilidad.series.length == 0" class="position-absolute h-100 w-100" style="top:0px;left:0px;opacity:0.8;filter:blur(6px);background-color:#ffffff;z-index:1;" />
                            <div v-if="utilidad.series.length == 0" class="img-sindatos text-center">
                                <img class="obj-cover" src="/img/otros/no_datos.svg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import echarts from 'echarts'
import moment from 'moment';
import Cupones from "~/services/cupones_estadisticas";
Vue.component('echarts',echarts);
let color = [ '#A78FE2', '#F1BC8D', '#72DCCF', '#F1E1AB', '#8592A4', '#71C1EC', '#D382C7', '#79BB88', '#9FDDF8', '#F171D8', '#7797F1', '#DC7279', '#B78471', '#76A0B8', '#87DC72', '#BA6BFF', '#56788B', '#FAB684', '#FF444B', '#F2C80F'];
let color2 = [ '#FF9539', '#03D6BC', '#6D3CE1', '#FFDF75', '#284367', '#0892DD', ]
var itemStyle = {
    opacity: 0.8,
    shadowBlur: 10,
    shadowOffsetX: 0,
    shadowOffsetY: 0,
    shadowColor: 'rgba(0, 0, 0, 0.5)'
};
export default {
    data(){
        let self = this
        return {
            inicio: 0,
            fin: 60,
            criterioGeneros: [],
            filters_name: [
                {
                    title: 'Hoy'
                },
                {
                    title: 'Última semana'
                },
                {
                    title: 'Último mes'
                }
            ],
            relaciones: [
                { title: 'Pedidos', porcentaje: 0, valor: 0 },
                { title: 'Valor de los pedidos', porcentaje: 0, valor: 0 },
                { title: 'Ticket promedio', porcentaje: 0, valor: 0 },
                { title: 'Valor obsequiado', porcentaje: 0, valor: 0 },
                { title: 'Obsequio promedio', porcentaje: 0, valor: 0 },
                { title: 'Porcentaje  de descuento', porcentaje: 0, valor: 0 },
            ],
            filters: [
                {
                    title: 'Pedidos',
                    porcentaje: -2.5
                },
                {
                    title: 'Valor de los pedidos',
                    porcentaje: 2.5
                },
                {
                    title: 'Ticket promedio',
                    porcentaje: 2.5
                },
                {
                    title: 'Valor obsequiado',
                    porcentaje: 1.5
                },
                {
                    title: 'Obsequio promedio',
                    porcentaje: 3.5
                },
                {
                    title: 'Porcentaje  de descuento',
                    porcentaje: 42.5
                }
            ],
            active_filter: 0,
            cuponesDisponiblesCedis: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    show: false,
                },
                yAxis: {
                    splitLine: {
                        lineStyle: {
                            color: "#AAAAAA56",
                        }
                    },
                },
                dataset:{
                    dimensions:['nombre', 'cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Cupones disponibles',
                        type: 'bar',
                        barMaxWidth: 30,
                        // data: [120, 132, 101, 134, 90, 230, 210],
                        itemStyle: {
                            normal: {
                                color: '#DBDBDB',
                            },
                        },
                    },
                ]
            },
            cuponesMayorCantidad: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        // var value = obj.value;
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].data.nombre}</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-blank f-18" style="color:${obj[0].color}" /><span class="text-general2 f-14">${self.agregarSeparadoresNumero(obj[0].data.cantidad)} ${obj[0].seriesName} </span>` + '<br>'
                        +  `<i class="icon-brightness f-18" style="color:${obj[1].color}" /><span class="text-general2 f-14">${self.separadorNumero(obj[1].data.valor_final_2)} ${obj[1].seriesName} </span>` + '<br>'
                        +  `<i class="icon-brightness f-18" style="color:${obj[2].color}" /><span class="text-general2 f-14">${self.separadorNumero(obj[2].data.valor_cupon)} ${obj[2].seriesName} </span>` + '<br>'
                        + '</div>'
                    }
                },
                legend: {
                    data: ['Usos del cupon', 'Ventas', 'Obsequio']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        // show: false
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        // show: false
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['nombre', 'cantidad','valor_final_2','valor_cupon'],
                    source: []
                },
                series: [
                    {
                        name: 'Usos del cupon',
                        type: 'bar',
                        barMaxWidth: 30,
                        // data: [120, 132, 101, 134, 90, 230, 210],
                        itemStyle: {
                            normal: {
                                color: '#DBDBDB',
                            },
                        },
                    },
                    {
                        name: 'Ventas',
                        // data: [220, 453, 301, 354, 290, 330, 320],
                        type: 'line',
                        symbol: 'circle',
                        yAxisIndex: 1,
                        symbolSize: 10,
                        itemStyle: {
                            normal: {
                                color: "#000000",
                            },
                        },
                    },
                    {
                        name: 'Obsequio',
                        // data: [213, 356, 123, 225, 78, 123, 354],
                        type: 'line',
                        symbol: 'circle',
                        yAxisIndex: 1,
                        symbolSize: 10,
                        itemStyle: {
                            normal: {
                                color: "#FF1720",
                            },
                        },
                    },
                ]
            },
            cuponesMayorCantidadSemana: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        // var value = obj.value;
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].data.semana}</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-blank f-18" style="color:${obj[0].color}" /><span class="text-general2 f-14">${self.agregarSeparadoresNumero(obj[0].data.cantidad)} ${obj[0].seriesName} </span>` + '<br>'
                        +  `<i class="icon-brightness f-18" style="color:${obj[1].color}" /><span class="text-general2 f-14">${self.separadorNumero(obj[1].data.valor_final_2)} ${obj[1].seriesName} </span>` + '<br>'
                        +  `<i class="icon-brightness f-18" style="color:${obj[2].color}" /><span class="text-general2 f-14">${self.separadorNumero(obj[2].data.valor_cupon)} ${obj[2].seriesName} </span>` + '<br>'
                        + '</div>'
                    }
                },
                legend: {
                    data: ['Usos del cupon', 'Ventas', 'Obsequio']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        show:false
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                        show:false
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        show: false
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['semana', 'cantidad','valor_final_2','valor_cupon'],
                    source: []
                },
                series: [
                    {
                        name: 'Usos del cupon',
                        type: 'bar',
                        barMaxWidth: 30,
                        // data: [120, 132, 101, 134, 90, 230, 210],
                        itemStyle: {
                            normal: {
                                color: '#DBDBDB',
                            },
                        },
                    },
                    {
                        name: 'Ventas',
                        // data: [220, 453, 301, 354, 290, 330, 320],
                        symbol: 'circle',
                        yAxisIndex: 1,
                        symbolSize: 10,
                        type: 'line',
                        itemStyle: {
                            normal: {
                                color: "#000000",
                            },
                        },
                    },
                    {
                        name: 'Obsequio',
                        // data: [213, 356, 123, 225, 78, 123, 354],
                        symbol: 'circle',
                        yAxisIndex: 1,
                        symbolSize: 10,
                        type: 'line',
                        itemStyle: {
                            normal: {
                                color: "#FF1720",
                            },
                        },
                    },
                ]
            },
            cuponesMayorCantidadMes: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        // var value = obj.value;
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].data.mes}</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-blank f-18" style="color:${obj[0].color}" /><span class="text-general2 f-14">${self.agregarSeparadoresNumero(obj[0].data.cantidad)} ${obj[0].seriesName} </span>` + '<br>'
                        +  `<i class="icon-brightness f-18" style="color:${obj[1].color}" /><span class="text-general2 f-14">${self.separadorNumero(obj[1].data.valor_final_2)} ${obj[1].seriesName} </span>` + '<br>'
                        +  `<i class="icon-brightness f-18" style="color:${obj[2].color}" /><span class="text-general2 f-14">${self.separadorNumero(obj[2].data.valor_cupon)} ${obj[2].seriesName} </span>` + '<br>'
                        + '</div>'
                    }
                },
                legend: {
                    data: ['Usos del cupon', 'Ventas', 'Obsequio']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                    },
                    {
                        type: 'category',
                        boundaryGap: true,
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        show: false
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['mes', 'cantidad','valor_final_2','valor_cupon'],
                    source: []
                },
                series: [
                    {
                        name: 'Usos del cupon',
                        type: 'bar',
                        barMaxWidth: 30,
                        // data: [120, 132, 101, 134, 90, 230, 210],
                        itemStyle: {
                            normal: {
                                color: '#DBDBDB',
                            },
                        },
                    },
                    {
                        name: 'Ventas',
                        // data: [220, 453, 301, 354, 290, 330, 320],
                        type: 'line',
                        symbol: 'circle',
                        yAxisIndex: 1,
                        symbolSize: 10,
                        itemStyle: {
                            normal: {
                                color: "#000000",
                            },
                        },
                    },
                    {
                        name: 'Obsequio',
                        // data: [213, 356, 123, 225, 78, 123, 354],
                        type: 'line',
                        symbol: 'circle',
                        yAxisIndex: 1,
                        symbolSize: 10,
                        itemStyle: {
                            normal: {
                                color: "#FF1720",
                            },
                        },
                    },
                ]
            },
            cuponesGeneros: {
                tooltip: {
                    trigger: 'axis',
                    show: true,
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        var value = obj.value;
                        // console.log(obj);
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].data.nombre}:</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-blank f-18" style="color:${obj[0].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[0].data.femenino)} ${obj[0].seriesName} </span>` + '<br>'
                        +  `<i class="icon-blank f-18" style="color:${obj[1].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[1].data.masculino)} ${obj[1].seriesName} </span>` + '<br>'
                        +  `<i class="icon-blank f-18" style="color:${obj[2].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[2].data.otro)} ${obj[2].seriesName} </span>` + '<br>'
                        + '</div>'
                    }
                },
                grid: {
                    left: '3%',
                    top: '20%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        // show: false
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    }
                ],
                color: [
                    '#000000',
                    '#DBDBDB',
                    '#707070',
                ],
                dataset:{
                    dimensions:['nombre', 'femenino','masculino','otro'],
                    source: []
                },
                series: [
                    {
                        name: 'Mujeres',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                    {
                        name: 'Hombres',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                    {
                        name: 'Otros',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                ]
            },
            cuponesEdades: {
                tooltip: {
                    trigger: 'axis',
                    show: true,
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        var value = obj.value;
                        // console.log(obj);
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].data.nombre}:</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-blank f-18" style="color:${obj[0].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[0].data.menos20)}   (${obj[0].seriesName}) </span>` + '<br>'
                        +  `<i class="icon-blank f-18" style="color:${obj[1].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[1].data.entre20a30)}   (${obj[1].seriesName}) </span>` + '<br>'
                        +  `<i class="icon-blank f-18" style="color:${obj[2].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[2].data.entre30a40)}   (${obj[2].seriesName}) </span>` + '<br>'
                        +  `<i class="icon-blank f-18" style="color:${obj[3].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[3].data.entre40a50)}   (${obj[3].seriesName}) </span>` + '<br>'
                        +  `<i class="icon-blank f-18" style="color:${obj[4].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[4].data.entre50a60)}   (${obj[4].seriesName}) </span>` + '<br>'
                        +  `<i class="icon-blank f-18" style="color:${obj[5].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[5].data.mas60)}   (${obj[5].seriesName}) </span>` + '<br>'
                        + '</div>'
                    }
                },
                grid: {
                    left: '3%',
                    top: '20%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        // show: false
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    }
                ],
                color: color2,
                dataset:{
                    dimensions:['nombre', 'menos20','entre20a30','entre30a40','entre40a50','entre50a60','mas60'],
                    source: []
                },
                series: [
                    {
                        name: '-20 años',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                    {
                        name: '20 - 30 Años',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                    {
                        name: '30 - 40 Años',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                    {
                        name: '40 - 50 Años',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                    {
                        name: '50 - 60 Años',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                    {
                        name: '+60 Años',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                ]
            },
            cuponesCantidadPedidos: {
                tooltip: {
                    trigger: 'axis',
                    show: true,
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        var value = obj.value;
                        // console.log(obj);
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].data.nombre}:</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-blank f-18" style="color:${obj[0].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[0].data.menos10)} (${obj[0].seriesName}) </span>` + '<br>'
                        +  `<i class="icon-blank f-18" style="color:${obj[1].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[1].data.entre10a20)} (${obj[1].seriesName}) </span>` + '<br>'
                        +  `<i class="icon-blank f-18" style="color:${obj[2].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[2].data.entre20a50)} (${obj[2].seriesName}) </span>` + '<br>'
                        +  `<i class="icon-blank f-18" style="color:${obj[3].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[3].data.entre50a100)} (${obj[3].seriesName}) </span>` + '<br>'
                        +  `<i class="icon-blank f-18" style="color:${obj[4].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[4].data.mas100)} (${obj[4].seriesName}) </span>` + '<br>'
                        + '</div>'
                    }
                },
                grid: {
                    left: '3%',
                    top: '20%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        // show: false
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    }
                ],
                color: color2,
                dataset:{
                    dimensions:['nombre', 'menos10','entre10a20','entre20a50','entre50a100','mas100'],
                    source: []
                },
                // -10 10 - 20 20 - 50 50 - 100 + 100
                series: [
                    {
                        name: '- 1',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                    {
                        name: '10 - 20',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                    {
                        name: '20 - 50',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                    {
                        name: '50 - 100',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                    {
                        name: '+ 100',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                ]
            },
            cuponesAntiguedad: {
                tooltip: {
                    trigger: 'axis',
                    show: true,
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        var value = obj.value;
                        // console.log(obj);
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].data.nombre}:</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-blank f-18" style="color:${obj[0].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[0].data.menos1)}   (${obj[0].seriesName}) </span>` + '<br>'
                        +  `<i class="icon-blank f-18" style="color:${obj[1].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[1].data.entre1a6)}   (${obj[1].seriesName}) </span>` + '<br>'
                        +  `<i class="icon-blank f-18" style="color:${obj[2].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[2].data.entre6a1)}   (${obj[2].seriesName}) </span>` + '<br>'
                        +  `<i class="icon-blank f-18" style="color:${obj[3].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[3].data.entre1a2)}   (${obj[3].seriesName}) </span>` + '<br>'
                        +  `<i class="icon-blank f-18" style="color:${obj[4].color}" /><span class="text-general2">${self.agregarSeparadoresNumero(obj[4].data.mas2)}   (${obj[4].seriesName}) </span>` + '<br>'
                        + '</div>'
                    }
                },
                grid: {
                    left: '3%',
                    top: '20%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        // show: false
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    }
                ],
                color: color2,
                dataset:{
                    dimensions:['nombre', 'menos1','entre1a6','entre6a1','entre1a2','mas2'],
                    source: []
                },
                series: [
                    {
                        name: '- 1 mes',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                    {
                        name: '1 - 6 Meses',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                    {
                        name: '6 Meses 1 año',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                    {
                        name: '1 - 2 Años',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                    {
                        name: '+ 2 Años',
                        type: 'bar',
                        stack: 'Tik Tok',
                        itemStyle: {
                            borderWidth: 1,
                        },
                    },
                ]
            },
            rangos: [
                { id: 1, nombre: "Hoy"},
                { id: 2, nombre: "Ayer"},
                { id: 3, nombre: "Última semana"},
                { id: 4, nombre: "Semana anterior"},
                { id: 5, nombre: "Último mes"},
                { id: 6, nombre: "Mes anterior"},
                { id: 7, nombre: "Último año"},
                { id: 8, nombre: "Personalizado"},
            ],
            rango: 5,
            fecha: [moment().subtract(1, 'month').toDate(),new Date()],
            disable_fecha: true,
            cargandoComparativos: true,
            cargandoCuponesCedis: true,
            cargandoCuponesFechas: true,
            cargandoInforme: true,
            cargandoCriterios: true,
            semana: null,
            ultimo_mes: null,
            hoy: null,
            options: [
                { id: 1, nombre: "Todos"},
                { id: 2, nombre: "Generos"},
                { id: 3, nombre: "Edad"},
                { id: 4, nombre: "Antiguedad"},
                { id: 5, nombre: "Cantidad de Pedidos"},
            ],
            criterio: 1,
            filtro: null,
            utilidad:{
                color: color,
                legend: {
                    type: 'scroll',
                    textStyle: {
                        color: '#000',
                        fontSize: 16
                    },

                },
                grid: {top: '30%'},
                tooltip: {
                    padding: 10,
                    backgroundColor: '#222',
                    borderColor: '#777',
                    borderWidth: 1,
                    formatter: function(obj){
                        var value = obj.value;
                        return '<div style="border-bottom: 1px solid rgba(255,255,255,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + obj.seriesName
                        + '</div>'
                        + 'Ventas：' + self.separadorNumero(value[0]) + '<br>'
                        + 'Obsequio：' + self.separadorNumero(value[1]) + '<br>'
                        + self.agregarSeparadoresNumero(value[2],1) + '%<br>'
                    }
                },
                xAxis: {
                    type: 'value',
                    name: 'Ventas',
                    nameGap: 16,
                    // max: ,
                    splitLine: {
                        show: false
                    },
                },
                yAxis: {
                    type: 'value',
                    name: 'Obsequio',
                    nameLocation: 'end',
                    // nameGap: 20,
                    splitLine: {
                        show: false
                    }
                },
                series: []
            },
        }
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
            id_cedis:'cedis/id_cedis',
        }),
        cargandoTerminado(){
            return  !this.cargandoComparativos &&
                    !this.cargandoCuponesCedis &&
                    !this.cargandoInforme &&
                    !this.cargandoCriterios
        },
        validarGrafica(){

            let grafica = {}
            switch (this.criterio){
            case 1:
                grafica = this.cuponesMayorCantidad
                break;
            case 2:
                grafica = this.cuponesGeneros
                break;
            case 3:
                grafica = this.cuponesEdades
                break;
            case 4:
                grafica = this.cuponesAntiguedad
                break;
            case 5:
                grafica = this.cuponesCantidadPedidos
                break;
            default:
                grafica = this.cuponesMayorCantidad
                break;
            }

            return _.sumBy(grafica.dataset.source,'cantidad') == 0 && _.sumBy(grafica.dataset.source,'valor_final_2') == 0
        }
    },
    watch: {
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'day').toDate()
                this.fecha[1] = moment().subtract(1, 'day').toDate()
                this.disable_fecha = true
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').startOf('week').toDate()
                this.fecha[1] = moment().subtract(1, 'week').endOf('week').toDate()
                this.disable_fecha = true
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 6:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').startOf('month').toDate()
                this.fecha[1] = moment().subtract(1, 'month').endOf('month').toDate()
                this.disable_fecha = true
                break;
            case 7:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'year').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 8:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                break;

            default:
                break;
            }
        },
        id_moneda(val,oldVal){
            if(oldVal == null) return
            this.cargando()
            this.montar()
        },
        id_cedis(val,oldVal){
            if(oldVal == null) return
            this.cargando()
            this.montar()
        },
        active_filter(){
            this.calcularComparativos()
        },
        filtro(val){
            if (val > 2){
                this.inicio = 0
                this.fin = val == 3 ? 60 : val == 4 ? 24 : 100
            }
        },
    },
    mounted(){
        this.montar()
    },
    methods: {
        cargando(informes = true){
            if (informes){
                this.cargandoComparativos = true
                this.cargandoCuponesCedis = true
                this.cargandoCuponesFechas = true
            }
            this.cargandoInforme = true
            this.cargandoCriterios = true
        },
        async montar(){
            try {
                if(this.id_moneda === null && this.id_cedis == null){
                    setTimeout(async() => {
                        await this.comparativos()
                        await this.cuponesCedis()
                        await this.aplicacionCuponesFechas()
                        await this.aplicacionCuponesInforme()
                        await this.cuponesIngresosInforme()
                    }, 1000);
                }else{
                    await this.comparativos()
                    await this.cuponesCedis()
                    await this.aplicacionCuponesFechas()
                    await this.aplicacionCuponesInforme()
                    await this.cuponesIngresosInforme()
                }
            } catch (error){
                console.log(error)
            }finally{
                this.primeraVez = false
            }
        },
        async comparativos(){
            try {
                let params = {
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis,
                }
                this.cargandoComparativos = true

                const { data } = await Cupones.comparativos(params)
                this.semana = data.data.semana
                this.ultimo_mes = data.data.ultimo_mes
                this.hoy = data.data.hoy
                this.calcularComparativos()
            } catch (error){
                console.log(error)
            } finally {
                this.cargandoComparativos = false
            }
        },
        calcularComparativos(){
            try {

                let tipo = {}
                switch (this.active_filter){
                case 0:
                    tipo = this.hoy
                    break;
                case 1:
                    tipo = this.semana
                    break;
                case 2:
                    tipo = this.ultimo_mes
                    break;
                default:
                    break;
                }

                this.relaciones[0].porcentaje = tipo.pedidos.porcentaje
                this.relaciones[0].valor = tipo.pedidos.valor
                this.relaciones[1].porcentaje = tipo.ventas.porcentaje
                this.relaciones[1].valor = tipo.ventas.valor
                this.relaciones[2].porcentaje = tipo.promedio.porcentaje
                this.relaciones[2].valor = tipo.promedio.valor
                this.relaciones[3].porcentaje = tipo.obsequios.porcentaje
                this.relaciones[3].valor = tipo.obsequios.valor
                this.relaciones[4].porcentaje = tipo.obsequios_promedio.porcentaje
                this.relaciones[4].valor = tipo.obsequios_promedio.valor
                this.relaciones[5].porcentaje = tipo.obsequios_porcentaje.porcentaje
                this.relaciones[5].valor = tipo.obsequios_porcentaje.valor

            } catch (error){

            }
        },
        async cuponesCedis(){
            try {
                let params = {
                    id_cedis: this.id_cedis,
                }
                this.cargandoCuponesCedis = true

                const { data } = await Cupones.cuponesCedis(params)

                this.cuponesDisponiblesCedis.dataset.source = data.cupones
            } catch (error){
                console.log(error)
            } finally {
                this.cargandoCuponesCedis = false
            }
        },
        async aplicacionCuponesFechas(){
            try {
                let params = {
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis,
                }
                this.cargandoCuponesFechas = true

                const {data} = await Cupones.aplicacionCuponesFechas(params)
                this.cuponesMayorCantidadSemana.dataset.source = data.semanas
                this.cuponesMayorCantidadMes.dataset.source = data.meses

            } catch (error){
                console.log(error)
            } finally {
                this.cargandoCuponesFechas = false
            }
        },
        async aplicacionCuponesInforme(){
            try {
                let params = {
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    id_cedis: this.id_cedis,
                    id_moneda: this.id_moneda,
                }
                this.cargandoInforme = true

                const {data} = await Cupones.aplicacionCuponesInforme(params)

                this.cuponesMayorCantidad.dataset.source = data.cupones
                this.cuponesGeneros.dataset.source = data.generos
                this.cuponesEdades.dataset.source = data.edades
                this.cuponesAntiguedad.dataset.source = data.antiguedad
                this.cuponesCantidadPedidos.dataset.source = data.cantidadPedidos
            } catch (error){
                console.log(error)
            } finally {
                this.cargandoInforme = false
            }
        },
        async cuponesIngresosInforme(){
            try {

                let params = {
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    id_cedis: this.id_cedis,
                    id_moneda: this.id_moneda,
                    criterio: this.filtro,
                    inicio: this.inicio,
                    fin: this.fin,
                    generos: this.criterioGeneros,
                }
                this.cargandoCriterios = true

                const {data} = await Cupones.cuponesIngresosInforme(params)
                let utilidades = []
                let max = Math.max(...data.utilidad.map(d=>d.descuento))

                data.utilidad.map(d=>{
                    let porcentaje = max == 0 ? 0 : Math.round((d.descuento / +max) * 100, 1)
                    let min = porcentaje <= 17 ? 17 : porcentaje
                    utilidades.push({
                        name: d.nombre,
                        type: 'scatter',
                        itemStyle: itemStyle,
                        data: [[d.valor_final_2, d.valor_cupon, d.descuento , min]],
                        symbolSize: function(data){
                            return data[3] * 0.75;
                        },
                    })
                })
                this.utilidad.series = utilidades

            } catch (error){
                console.log(error)
            } finally {
                this.cargandoCriterios = false
            }
        },
        async informe(){
            try {
                this.cargando(false)
                await this.aplicacionCuponesInforme()
                await this.cuponesIngresosInforme()
            } catch (error){
                console.log(error)
            }
        },
        async informeCriterio(){
            try {
                await this.cuponesIngresosInforme()
            } catch (error){
                console.log(error)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.active-filter{
    color: var(--text-general) !important;
    border-bottom: 3px solid var(--text-general) !important;
}
.inactive-filter{
    color: var(--color-general2) !important;
    border-bottom: 3px solid var(--color-light) !important;
}
.card-filter{
    width: 232px;
    .title-filter{
        height: 42px;
    }
    .inactive-filter{
        color: var(--color-general2) !important;
        border-bottom: 3px solid var(--color-light) !important;
    }
    .card-filter{
        min-width: 230px;
        width: 180px;
        .bg-light-f5{
            background: #F6F9FB;
        }
        .title-filter{
            height: 42px;
        }
    }
    .img-sindatos{
        position: absolute;
        z-index: 3;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0%;
        left: 0px;
        width: 100%;
        height: 100%;
        img{
            // border-radius: 50%;
            /* max-height: 100%; */
            box-shadow: 0px 3px 6px #00000029;
            max-width: 50%;
            height: 50%;
        }
        .mensaje{
            color: #fff;
            text-shadow: 0 2px 3px #000000;
            position: absolute;
            top: 58%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
</style>
